import React from 'react'
import { Row, Col, Popover} from 'antd'
import { connect } from 'react-redux'
// import { push } from 'react-router-redux'
import actions from 'redux/virtual/event/actions'
import SpeakerInfo from '../SpeakerInfo'
// import { BellFilled, PlayCircleFilled } from '@ant-design/icons'

import './speaker.less'

const mapStateToProps = ({ router }) => ({ router })
@connect(mapStateToProps)
class SessionList extends React.Component {
  initiateSession = event => {
    const { dispatch } = this.props
    const id = event.target.getAttribute('data-id')
    const scene = event.target.getAttribute('data-room')
    const url = event.target.getAttribute('data-url')
    dispatch({
      type: 'virtualSettings/CHANGE_SETTING',
      payload: {
        setting: 'isAgendaOpen',
        value: false,
      },
    })
    dispatch({
      type: actions.FILTER_SCENE,
      payload: {
        id: scene,
        navigated: true,
        componentRendered: false,
        sessionID: id,
        currentSessionURL: url,
      },
    })
    // dispatch(push(`${pathname}?scene=${scene}&session=${id}`))
  }

  getSpeakerItem = item => {
    // const { isVirtualMobileView } = this.props
    return (
      <>
        <Popover trigger="click" placement="leftTop" content={<SpeakerInfo speakerData={item} imageUrl={item[1].imageUrl} />} style={{maxWidth:'300px'}}>
          <Col xs={8} md={8} lg={8} xl={8} className="speakerDetails text-center">
            <Row gutter={0} className="speakerImage" justify="space-around" align="middle">
              <Col span={24}>
                <img src={item[1].imageUrl} className="thespeakerImage" alt={item[1].speakerName} />
              </Col>
            </Row>
            <Row>
              <Col span={24} className="text-center speakerName">
                <b>
                  <h5>{item[1].speakerName}</h5>
                </b>
              </Col>
            </Row>
            <Row>
              <Col span={24} className="text-center speakerIntro">
                <h7>{item[1].smallIntro}</h7>
              </Col>
            </Row>
          </Col>
        </Popover>
      </>
    )
  }

  render() {
    const { speakers } = this.props
    const showcase = Object.entries(speakers).map(key => {
      return this.getSpeakerItem(key)
    })
    return (
      <div>
        <Row gutter={0} className="speakerContainer" justify="space-around">
          {showcase}
        </Row>
      </div>
    )
  }
}

export default SessionList
