import React from 'react'
import { 
  Popover, 
  Row, 
  Col, 
  Divider, 
  Button, 
  Avatar, 
  Space 
} from 'antd'
import { connect } from 'react-redux'
// import { push } from 'react-router-redux'
import actions from 'redux/virtual/event/actions'
import { PlayCircleFilled } from '@ant-design/icons'
import SpeakerMiniProfile from '../SpeakerMiniProfile'

import './session.less'

const mapStateToProps = ({ router }) => ({ router })
@connect(mapStateToProps)
class SessionList extends React.Component {
  initiateSession = event => {
    const { dispatch } = this.props
    // const id = event.target.getAttribute('data-id')
    const scene = event.target.getAttribute('data-room')
    // const url = event.target.getAttribute('data-url')
    dispatch({
      type: 'virtualSettings/CHANGE_SETTING',
      payload: {
        setting: 'isAgendaOpen',
        value: false,
      },
    })
    dispatch({
      type: actions.FILTER_SCENE,
      payload: {
        id: scene,
        navigated: true,
        componentRendered: false,
        // sessionID: id,
        // currentSessionURL: url,
        // callScene: agendaType !== "local"
      },
    })
    // dispatch(push(`${pathname}?scene=${scene}&session=${id}`))
  }

  getAgendaItem = item => {
    const startTime = item[1].startTime.split(' ')[1]
    const endTime = item[1].endTime.split(' ')[1]
    const { isVirtualMobileView, agendaType } = this.props
    const theSpeakers = Object.entries(item[1].speakers).map(key => {
      console.log("checking speakers")
      console.log(key)
      return (
        <Popover
          overlayClassName="speakerpopover"
          content={<SpeakerMiniProfile speakerData={key[1]} />}
        >
          <Avatar
            src={key[1].imageUrl}
            border
            borderColor="white"
            shape="square"
            size={75}
            style={{ borderRadius: '10px' }}
          />
        </Popover>
      )
    })

    let showJoinSession = false
    console.log("Checking Session details")
    console.log(item[1])
    if(agendaType === "global")
    {
      if(item[1].breakoutToggle)
      {
        showJoinSession = true
      }
    }
    console.log(showJoinSession)
    return (
      <div className="sessionItem">
        <Row gutter={0} className="sessionHeader" justify="space-around" align="middle">
          <Col xs={13} md={5} lg={5} xl={5} className="sessionTime text-center">
            <h3>
              {startTime} - {endTime}
            </h3>
          </Col>
          {isVirtualMobileView && showJoinSession ? (
            <>
              <Col xs={1} />
              <Col xs={10}>
                <Button
                  type="primary"
                  shape="round"
                  data-url={item[1].url}
                  data-id={item[0]}
                  data-room={item[1].assignedRoom}
                  icon={<PlayCircleFilled />}
                  onClick={this.initiateSession}
                  size="small"
                >
                  Join Breakout Session
                </Button>
              </Col>
            </>
          ) : (
            ''
          )}
          <Col xs={0} md={1} lg={1} xl={1} />
          <Col xs={24} md={18} lg={18} xl={18} className="sessionTitle text-left">
            <h3><div dangerouslySetInnerHTML={{ __html: item[1].title }} /></h3>
            {/* <h3>{item[1].title}</h3> */}
          </Col>
          {/* <Col xs={0} md={6} lg={6} xl={6} className="text-center speakerProfileBig">
            <Space>{theSpeakers}</Space>
          </Col> */}
        </Row>
        {!isVirtualMobileView && showJoinSession ? (
          <Row gutter={0} className="sessionActions" justify="space-around" align="middle">
            <Col xs={24} md={10} lg={8} xl={6}>
              <Button
                type="primary"
                shape="round"
                data-url={item[1].url}
                data-id={item[0]}
                data-room={item[1].assignedRoom}
                icon={<PlayCircleFilled />}
                onClick={this.initiateSession}
                size="medium"
              >
                Join Breakout Session
              </Button>
            </Col>
            <Col xs={0} md={14} lg={16} xl={18} />
          </Row>
        ) : (
          ''
        )}
        <Row className="sessionContent">
          <Col span={24} className="text-center">
            <div dangerouslySetInnerHTML={{ __html: item[1].description }} />
            {/* <p>{item[1].description}</p> */}
          </Col>
        </Row>
        {theSpeakers.length > 0 
        ?
          <>
            <Divider />
            <Row className="speakerProfile">
              <Col span={24}>
                <Space>{theSpeakers}</Space>
              </Col>
            </Row>
          </>
        :
          ''
        }
        {/* {isVirtualMobileView ? (
          <>
            <Divider />
            <Row className="speakerProfile">
              <Col span={24}>
                <Space>{theSpeakers}</Space>
              </Col>
            </Row>
          </>
          )
          :
          ''
        } */}
        {/* // <Row gutter={24} id="agendaFooter" className="agendaFooter">
        //   <Col span={24}>
        //     <img src="./resources/images/agendaFooter.jpg" style={{width:"100%"}} alt="modalFooter" />
        //   </Col>
        // </Row> */}
      </div>
    )
  }

  render() {
    const { sessions } = this.props
    const showcase = Object.entries(sessions).map(key => {
      return this.getAgendaItem(key)
    })
    return (
      <div>
        {showcase}
      </div>
    )
  }
}

export default SessionList
