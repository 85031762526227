import React from 'react'
import { connect } from 'react-redux'
import Capture from './Capture'
import Confirmation from './Confirmation'
// import './booth.less'

const steps = [<Capture />, <Confirmation />]

const mapStateToProps = ({ mosaic }) => ({ mosaic })
@connect(mapStateToProps)
class MosaicPhotobooth extends React.Component {
  render() {
    const {
      mosaic: { currentStep },
    } = this.props
    return <div className="text-center">{steps[currentStep]}</div>
  }
}

export default MosaicPhotobooth
