import React from 'react'
import { Modal, Row, Col, Tooltip } from 'antd'
import { connect } from 'react-redux'
import { CloseCircleTwoTone } from '@ant-design/icons'
import { Scrollbars } from 'react-custom-scrollbars'
import Iframe from 'react-iframe'
import actions from 'redux/virtual/event/actions'

import './socialwall.less'

const mapStateToProps = ({ virtualEvent, virtualUser }) => ({ virtualEvent,virtualUser })

@connect(mapStateToProps)
class SocialWall extends React.Component {
  // constructor(props) {
  //   super(props)
  //   props.dispatch({
  //     type: actions.GET_AGENDA,
  //     payload: {},
  //   })
  // }

  handleClose = () => {
    const { dispatch } = this.props
    dispatch({
      type: actions.SET_STATE,
      payload: {
        isSocialWallOpen: false,
      },
    })
  }

  render() {
    const {
      virtualEvent: { isSocialWallOpen, socialWallURL,widgetID },
      virtualUser: {id}
    } = this.props
    return (
      <div>
        <Modal
          visible={isSocialWallOpen}
          style={{
            top: 0,
            display: 'flex',
          }}
          wrapClassName="socialwallModal"
          maskClosable={false}
          // maskStyle={{ marginTop: '48px' }}
          mask
          width="90vw"
          onOk={this.handleClose}
          confirmLoading
          onCancel={this.handleClose}
          footer={null}
          closeIcon={<CloseCircleTwoTone twoToneColor="#eb2f96" style={{ fontSize: '25px' }} />}
          destroyOnClose
        >
          {/* <Row gutter={24} id="modalHeader" className="modalHeader">
            <Col span={24}>
              <img
                src="./resources/images/agendaHeader.jpg"
                style={{ width: '100%' }}
                alt="modalHeader"
              />
            </Col>
          </Row> */}
          <Row id="modalContent" className="modalContent">
            <Scrollbars autoHeight autoHeightMin="100px" autoHeightMax="calc(100vh - 120px)">
              <Col span={24}>
                <Iframe
                  url={`${socialWallURL}?userid=${id}&widgetid=${widgetID}`}
                  width="100%"
                  height="calc(100vh - 120px)"
                  // id={newGameID}
                  // key={newGameID}
                  loading="eager"
                  className="theiFrame"
                  display="initial"
                  position="relative"
                  frameborder="0"
                  allow="camera"
                  // style={{border:0,height:"800px",width:"100%"}}
                  // loading="lazy"
                />
              </Col>
            </Scrollbars>
          </Row>
          <Row align="middle" id="modalFooter" className="modalFooter">
            <Col span={12} className="text-right">
              <h5>Keen to know more about Flipkart and all that&#39;s happening? Find us here:</h5>
            </Col>
            <Col span={1} className="text-center">
              <Tooltip title="Flipkart Tech Blog" placement="top">
                <a href="https://tech.flipkart.com/" target="_blank" rel="noopener noreferrer"><img src="resources/images/pngicons/flipkart.png" alt="Flipkart Tech" style={{width: "50%"}} /></a>
              </Tooltip>
            </Col>
            <Col span={1} className="text-center">
              <Tooltip title="Flipkart Stories" placement="top">
                <a href="https://stories.flipkart.com/" target="_blank" rel="noopener noreferrer"><img src="resources/images/pngicons/fkstories.png" alt="FK Stories" style={{width: "50%"}} /></a>
              </Tooltip>
            </Col>
            <Col span={1} className="text-center">
              <Tooltip title="Follow us on Youtube" placement="top">
                <a href="https://www.youtube.com/channel/UCcBtiDHo-ureBcwcr0iukxQ" target="_blank" rel="noopener noreferrer"><img src="resources/images/pngicons/youtube-play.png" alt="Flipkart Youtube Channel" style={{width: "50%"}} /></a>
              </Tooltip>
            </Col>
            <Col span={1} className="text-center">
              <Tooltip title="Follow us on Linkedin" placement="top">
                <a href="https://www.linkedin.com/company/flipkart" target="_blank" rel="noopener noreferrer"><img src="resources/images/pngicons/linkedin.png" alt="FK Linkedin" style={{width: "50%"}} /></a>
              </Tooltip>
            </Col>
            <Col span={1} className="text-center">
              <Tooltip title="Follow us on Facebook" placement="top">
                <a href="https://www.facebook.com/workatflipkart" target="_blank" rel="noopener noreferrer"><img src="resources/images/pngicons/facebook.png" alt="FK Facebook" style={{width: "50%"}} /></a>
              </Tooltip>
            </Col>
            <Col span={1} className="text-center">
              <Tooltip title="Follow us on Twitter" placement="top">
                <a href="https://twitter.com/WorkAtFlipkart" target="_blank" rel="noopener noreferrer"><img src="resources/images/pngicons/twitter.png" alt="FK Twitter" style={{width: "50%"}} /></a>
              </Tooltip>
            </Col>
            <Col span={1} className="text-center">
              <Tooltip title="Follow us on Instagram" placement="top">
                <a href="https://www.instagram.com/lifeatflipkart/" target="_blank" rel="noopener noreferrer"><img src="resources/images/pngicons/instagram.png" alt="Instagram" style={{width: "50%"}} /></a>
              </Tooltip>
            </Col>
          </Row>
        </Modal>
      </div>
    )
  }
}

export default SocialWall
