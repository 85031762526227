import actions from './actions'

const initialState = {
  isMosaicWallOpen: false,
  mosaicWallURL: '',
  mosaicWallTempURL: '',
  mosaicID: '7904c54d-f625-4df3-9244-a5a4b3df701e',
  webcamLoading: true,
  mosaicphotoboothImage: '',
  currentStep: 0,
  MOSAICALLY_API_KEY: '39d890fc-d854-4c5d-b67a-566ed918d883',
  imageSubmitLoading: false,
  webcamvisible: false,
  mosaiciFrameKey: 'initialkey',
  isMosaicIntroOpen:false
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
