import { all } from 'redux-saga/effects'
import user from './user/sagas'
import menu from './menu/sagas'
import settings from './settings/sagas'
import virtualMenu from './virtual/menu/sagas'
import virtualSettings from './virtual/settings/sagas'
import virtualSetup from './virtual/setup/sagas'
import stallDesign from './virtual/stalldesign/sagas'
import virtualEvent from './virtual/event/sagas'
import virtualUser from './virtual/user/sagas'
import registerVirtualUser from './virtual/registerUser/sagas'
import Chat from './virtual/chat/sagas'
import agenda from './virtual/agenda/sagas'
import streamFeature from './virtual/streamfeature/sagas'
import meetings from './virtual/meetings/sagas'
import photobooth from './virtual/photobooth/sagas'
import aiphotobooth from './virtual/aiphotobooth/sagas'
import toonphotobooth from './virtual/toonphotobooth/sagas'
import payment from './virtual/payment/sagas'
import analytics from './virtual/analytics/sagas'
import directory from './virtual/directory/sagas'
import stallConfigurator from './virtual/stallconfigurator/sagas'
import pointredeem from './virtual/pointredeem/sagas'
import contest from './virtual/contest/sagas'
import reactions from './virtual/reactions/sagas'
import messagebox from './virtual/messagebox/sagas'
import eshop from './virtual/eshop/sagas'
import mosaic from './virtual/mosaic/sagas'


export default function* rootSaga() {
  yield all([
    user(),
    menu(),
    settings(),
    virtualMenu(),
    virtualSettings(),
    virtualSetup(),
    virtualEvent(),
    virtualUser(),
    registerVirtualUser(),
    Chat(),
    agenda(),
    streamFeature(),
    meetings(),
    photobooth(),
    aiphotobooth(),
    payment(),
    analytics(),
    directory(),
    stallDesign(),
    stallConfigurator(),
    pointredeem(),
    contest(),
    toonphotobooth(),
    reactions(),
    messagebox(),
    eshop(),
    mosaic()
  ])
}
