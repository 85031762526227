import React from 'react'
import { Row, Col, Avatar, Button } from 'antd'
// import { push } from 'react-router-redux'
// import actions from 'redux/virtual/event/actions'

import './miniprofile.less'

class SpeakerInfo extends React.Component {
  render() {
    const { speakerData, imageUrl } = this.props
    console.log('Detailled intro')
    console.log(speakerData)
    return (
      <div className="miniprofile">
        <Row justify='center'>
          <Col span={24}>
            <Row>
              <Col span={8} />
              <Col span={8}>
                <Avatar
                  src={imageUrl}
                  border
                  borderColor="white"
                  shape="square"
                  size={150}
                  style={{ borderRadius: "10px 10px 0px 0px" }}
                />
              </Col>
              <Col span={8} />
            </Row>
          </Col>
        </Row>
        <br />
        <Row>
          <Col span={24} className="text-center speakerIntro">
            <Button href={speakerData[1].assignedUrl} type='primary' target='_blank'>LinkedIn Profile</Button>
          </Col>
        </Row>
        <Row>
          <Col span={24} className="text-center speakerName">
            <p>{speakerData[1].detailedIntro}</p>
          </Col>
        </Row>
        <Row>
          <Col span={24} className="text-center speakerIntro">
            <h7>{speakerData.smallIntro}</h7>
          </Col>
        </Row>
      </div>
    )
  }
}

export default SpeakerInfo
