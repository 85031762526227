import React from 'react'
import { Modal, Row, Col } from 'antd'
import { connect } from 'react-redux'
import { CloseCircleOutlined } from '@ant-design/icons'
import { Scrollbars } from 'react-custom-scrollbars'
import actions from 'redux/virtual/photobooth/actions'
import Booth from './Booth'

import './photobooth.less'

const mapStateToProps = ({ photobooth }) => ({ photobooth })

@connect(mapStateToProps)
class Photobooth extends React.Component {
  // constructor(props) {
  //   super(props)
  //   props.dispatch({
  //     type: actions.GET_AGENDA,
  //     payload: {},
  //   })
  // }

  handleClose = () => {
    const { dispatch } = this.props
    dispatch({
      type: actions.SET_STATE,
      payload: {
        isPhotoboothOpen: false,
        currentStep: 0,
        imageSrc: '',
        imageProcessing: false,
        webcamLoading: true,
      },
    })
  }

  render() {
    const {
      photobooth: { isPhotoboothOpen },
    } = this.props
    return (
      <div>
        <Modal
          visible={isPhotoboothOpen}
          // style={{
          //   top: 0,
          //   display: 'flex',
          // }}
          wrapClassName="photoboothModal"
          maskClosable={false}
          // maskStyle={{ marginTop: '48px' }}
          centered
          mask
          width="75vmax"
          onOk={this.handleClose}
          confirmLoading
          onCancel={this.handleClose}
          footer={null}
          closeIcon={<CloseCircleOutlined style={{ color: 'rgb(244,230,28)', fontSize: '25px' }} />}
          // destroyOnClose
        >
          {/* <Row gutter={24} id="modalHeader" className="modalHeader">
            <Col span={24}>
              <img
                src="./resources/images/agendaHeader.jpg"
                style={{ width: '100%' }}
                alt="modalHeader"
              />
            </Col>
          </Row> */}
          <Row id="modalContent" className="modalContent">
            <Scrollbars autoHeight autoHeightMin="100px" autoHeightMax="calc(100vh - 50px)">
              <Col span={24}>
                <Booth />
              </Col>
            </Scrollbars>
          </Row>
          {/* <Row gutter={24} id="modalFooter" className="modalFooter">
            <Col span={24}>
              <img
                src="./resources/images/agendaFooter.jpg"
                style={{ width: '100%' }}
                alt="modalFooter"
              />
            </Col>
          </Row> */}
        </Modal>
      </div>
    )
  }
}

export default Photobooth
