import React from 'react'
import { Modal, Row, Col } from 'antd'
import { connect } from 'react-redux'
import { CloseCircleTwoTone } from '@ant-design/icons'
import { Scrollbars } from 'react-custom-scrollbars'
import Iframe from 'react-iframe'
import actions from 'redux/virtual/event/actions'

import './feedback.less'

const mapStateToProps = ({ virtualEvent, virtualUser }) => ({ virtualEvent,virtualUser })

@connect(mapStateToProps)
class Feedback extends React.Component {
  // constructor(props) {
  //   super(props)
  //   props.dispatch({
  //     type: actions.GET_AGENDA,
  //     payload: {},
  //   })
  // }

  handleClose = () => {
    const { dispatch } = this.props
    dispatch({
      type: actions.SET_STATE,
      payload: {
        isFeedbackOpen: false,
      },
    })
  }

  render() {
    const {
      virtualEvent: { isFeedbackOpen, feedbackurl,widgetID },
      virtualUser: {id,name,email}
    } = this.props
    return (
      <div>
        <Modal
          visible={isFeedbackOpen}
          style={{
            top: 0,
            display: 'flex',
          }}
          wrapClassName="feedbackModal"
          maskClosable={false}
          // maskStyle={{ marginTop: '48px' }}
          mask
          width="90vw"
          onOk={this.handleClose}
          confirmLoading
          onCancel={this.handleClose}
          footer={null}
          closeIcon={<CloseCircleTwoTone twoToneColor="#eb2f96" style={{ fontSize: '25px' }} />}
          destroyOnClose
        >
          {/* <Row id="modalHeader" className="modalHeader">
            <Col span={24} className="text-center">
              <h3>Tell us about your experience at slash n 2022</h3>
            </Col>
          </Row> */}
          <Row id="modalContent" className="modalContent">
            <Scrollbars autoHeight autoHeightMin="100px" autoHeightMax="calc(100vh - 50px)">
              <Col span={24}>
                <Iframe
                  url={`${feedbackurl}?userid=${id}&email=${email}&name=${name}&widgetid=${widgetID}`}
                  width="100%"
                  height="calc(100vh - 50px)"
                  // id={newGameID}
                  // key={newGameID}
                  loading="eager"
                  className="theiFrame"
                  display="initial"
                  position="relative"
                  frameborder="0"
                  allow="camera"
                  // style={{border:0,height:"800px",width:"100%"}}
                  // loading="lazy"
                />
              </Col>
            </Scrollbars>
          </Row>
          {/* <Row gutter={24} id="modalFooter" className="modalFooter">
            <Col span={24}>
              <img
                src="./resources/images/agendaFooter.jpg"
                style={{ width: '100%' }}
                alt="modalFooter"
              />
            </Col>
          </Row> */}
        </Modal>
      </div>
    )
  }
}

export default Feedback
