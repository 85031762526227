import React from 'react'
import { Modal, Row, Col } from 'antd'
import { connect } from 'react-redux'
import { CloseCircleTwoTone } from '@ant-design/icons'
import { Scrollbars } from 'react-custom-scrollbars'
import Iframe from 'react-iframe'
import actions from 'redux/virtual/event/actions'

import './socialwall.less'

const mapStateToProps = ({ virtualEvent, virtualUser }) => ({ virtualEvent, virtualUser })

@connect(mapStateToProps)
class VideoCall extends React.Component {
  // constructor(props) {
  //   super(props)
  //   props.dispatch({
  //     type: actions.GET_AGENDA,
  //     payload: {},
  //   })
  // }

  handleClose = () => {
    const { dispatch } = this.props
    dispatch({
      type: actions.SET_STATE,
      payload: {
        isVideoCallOpen: false,
      },
    })
  }

  render() {
    const {
      virtualEvent: { isVideoCallOpen,meetingurl,meetinghosturl },
      virtualUser: {name, roles}
    } = this.props
    return (
      <div>
        <Modal
          visible={isVideoCallOpen}
          style={{
            top: 0,
            display: 'flex',
          }}
          wrapClassName="videoCallModal"
          maskClosable={false}
          // maskStyle={{ marginTop: '48px' }}
          mask
          width="80vw"
          onOk={this.handleClose}
          confirmLoading
          onCancel={this.handleClose}
          footer={null}
          closeIcon={<CloseCircleTwoTone twoToneColor="#eb2f96" style={{ fontSize: '25px' }} />}
          destroyOnClose
        >
          {/* <Row gutter={24} id="modalHeader" className="modalHeader">
            <Col span={24}>
              <img
                src="./resources/images/agendaHeader.jpg"
                style={{ width: '100%' }}
                alt="modalHeader"
              />
            </Col>
          </Row> */}
          <Row id="modalContent" className="modalContent">
            <Scrollbars autoHeight autoHeightMin="100px" autoHeightMax="calc(100vh - 50px)">
              <Col span={24}>
                <Iframe
                  url={
                    roles.includes('ROLE_ADMIN')
                      ? `${meetinghosturl}&displayName=BS-${name}&leaveButton=off&roomIntegrations=on&recording=on`
                      : `${meetingurl}?displayName=${name}&leaveButton=off`
                  }
                  width="100%"
                  // height={`${Math.floor(height)}px`}
                  loading="eager"
                  className="theiFrame"
                  display="initial"
                  position="relative"
                  frameborder="0"
                  styles={{ background: '#002245 !important' }}
                  allow="camera; microphone; fullscreen; speaker; display-capture"
                />
              </Col>
            </Scrollbars>
          </Row>
          {/* <Row gutter={24} id="modalFooter" className="modalFooter">
            <Col span={24}>
              <img
                src="./resources/images/agendaFooter.jpg"
                style={{ width: '100%' }}
                alt="modalFooter"
              />
            </Col>
          </Row> */}
        </Modal>
      </div>
    )
  }
}

export default VideoCall
