import React from 'react'
import { Row, Col, Button, Tooltip } from 'antd'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import actions from 'redux/virtual/mosaic/actions'

const mapStateToProps = ({ mosaic }) => ({ mosaic })
@connect(mapStateToProps)
class Confirmation extends React.Component {
  // capture = () => {
  //   const {
  //     dispatch,
  //     mosaic: { currentStep },
  //   } = this.props
  //   const { countdown } = this.state
  //   this.setState({ startCountdown: true })
  //   setInterval(() => {
  //     this.setState({ countdown: this.state.countdown - 1 })
  //   }, 1000)
  //   setTimeout(() => {
  //     const imageSrcCaptured = this.webcam.getScreenshot()
  //       dispatch({
  //         type: actions.SET_STATE,
  //         payload: {
  //           currentStep: currentStep + 1,
  //           mosaicphotoboothImage: imageSrcCaptured,
  //         },
  //       })
  //   }, countdown * 1000 + 100)
  // }

  retakePhoto = () => {
    const {
      dispatch,
      mosaic: { currentStep },
    } = this.props

    dispatch({
      type: actions.SET_STATE,
      payload: {
        currentStep: currentStep - 1,
        mosaicphotoboothImage: '',
        webcamLoading: true,
        imageSubmitLoading: false,
      },
    })
  }

  approvePhoto = () => {
    const {
      dispatch,
      mosaic: { mosaicphotoboothImage },
    } = this.props

    dispatch({
      type: actions.SET_STATE,
      payload: {
        imageSubmitLoading: true,
      },
    })

    dispatch({
      type: actions.UPLOAD_MOSAIC_IMAGE,
      payload: {
        uploadtype: 'base64',
        imageString: mosaicphotoboothImage,
      },
    })
  }

  render() {
    const {
      mosaic: { mosaicphotoboothImage, imageSubmitLoading },
    } = this.props
    return (
      <>
        <Row
          justify="space-around"
          align="middle"
          className="text-center"
          style={{ width: '400px' }}
        >
          <Col span={24} className="text-center">
            <img src={mosaicphotoboothImage} alt="mosaicImage" style={{ width: '100%' }} />
          </Col>
        </Row>
        <Row justify="space-around" align="middle" style={{ padding: '15px 0 15px 0' }}>
          <Col span={12} className="text-center">
            <Tooltip placement="top" title="Retake Selfie">
              <Button
                type="primary"
                shape="circle"
                onClick={this.retakePhoto}
                icon={<CloseOutlined />}
                size="large"
              />
            </Tooltip>
          </Col>
          <Col span={12} className="text-center">
            <Tooltip placement="top" title="Confirm and Upload the Selfie">
              <Button
                type="primary"
                shape="circle"
                onClick={this.approvePhoto}
                loading={imageSubmitLoading}
                icon={<CheckOutlined />}
                size="large"
              />
            </Tooltip>
          </Col>
        </Row>
      </>
    )
  }
}

export default Confirmation
