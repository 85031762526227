// import axios from 'axios'
// const FileSaver = require('file-saver')

export async function downloadDocument(data) {
  // FileSaver.saveAs(data.url, data.filename)
  window.open(data.url)
  return ''
  // return axios.get(data.url, {
  //     headers: {
  //       // "Content-Type": "application/octet-stream",
  //       "Content-Disposition": `attachment; filename=${data.filename};  filename*=${data.filename};`
  //     }
  // })
}

export async function getDefaultScene() {
  return ''
}
