import React from 'react'
import { Row, Col, Button, Tooltip } from 'antd'
import { CameraOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import Loader from 'components/LayoutComponents/Loader'
import actions from 'redux/virtual/mosaic/actions'
import Webcam from 'react-webcam'

/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */

const mapStateToProps = ({ mosaic }) => ({ mosaic })
/* eslint-disable react/destructuring-assignment, react/no-access-state-in-setstate */
@connect(mapStateToProps)
class Capture extends React.Component {
  state = {
    countdown: 3,
    startCountdown: false,
  }

  setRef = webcam => {
    this.webcam = webcam
  }

  capture = () => {
    const {
      dispatch,
      mosaic: { currentStep },
    } = this.props
    const { countdown } = this.state
    this.setState({ startCountdown: true })
    setInterval(() => {
      this.setState({ countdown: this.state.countdown - 1 })
    }, 1000)
    setTimeout(() => {
      const imageSrcCaptured = this.webcam.getScreenshot()
      dispatch({
        type: actions.SET_STATE,
        payload: {
          currentStep: currentStep + 1,
          mosaicphotoboothImage: imageSrcCaptured,
        },
      })
    }, countdown * 1000 + 100)
  }

  disableLoader = () => {
    const { dispatch } = this.props
    dispatch({
      type: actions.SET_STATE,
      payload: {
        webcamLoading: false,
      },
    })
  }

  render() {
    const {
      mosaic: { webcamLoading },
    } = this.props
    const { countdown, startCountdown } = this.state
    const videoConstraints = {
      width: 400,
      height: 400,
      facingMode: 'user',
    }
    return (
      <>
        <Row justify="space-around" align="middle" className="text-center">
          <Col span={24} className="text-center" style={{ width: '100%', minHeight: '250px' }}>
            {webcamLoading ? <Loader /> : ''}
            {startCountdown ? <h2 className="mosaicCountdown">{countdown}</h2> : ''}
            <Webcam
              audio={false}
              imageSmoothing
              mirrored
              style={{ width: '100%' }}
              ref={this.setRef}
              minScreenshotHeight={600}
              minScreenshotWidth={600}
              screenshotQuality={1}
              screenshotFormat="image/jpg"
              onUserMedia={this.disableLoader}
              videoConstraints={videoConstraints}
            />
          </Col>
        </Row>
        <Row justify="space-around" align="middle" style={{ padding: '15px 0 15px 0' }}>
          <Col span={24} className="text-center">
            <Tooltip placement="top" title="Take a Selfie">
              <Button
                type="primary"
                shape="circle"
                onClick={this.capture}
                icon={<CameraOutlined />}
                size="large"
              />
            </Tooltip>
          </Col>
        </Row>
      </>
    )
  }
}

export default Capture
