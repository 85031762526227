import React from 'react'
import {
  Modal,
  Row,
  Col,
  // Upload,
  // message,
  // Tooltip,
  Popover,
  Button,
} from 'antd'
import { connect } from 'react-redux'
import {
  CloseCircleOutlined,
  CameraOutlined,
  PlayCircleFilled,
  // UploadOutlined
} from '@ant-design/icons'
import { Scrollbars } from 'react-custom-scrollbars'
import Iframe from 'react-iframe'
import actions from 'redux/virtual/mosaic/actions'
import MosaicPhotobooth from './MosaicPhotobooth'

import './mosaicwall.less'

const mapStateToProps = ({ mosaic }) => ({ mosaic })

@connect(mapStateToProps)
class MosaicWall extends React.Component {
  // constructor(props) {
  //   super(props)
  //   props.dispatch({
  //     type: actions.GET_AGENDA,
  //     payload: {},
  //   })
  // }

  // beforeUpload = (file) => {

  //   const extension = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase()
  //   let filePermitted = false
  //   if(extension === "jpg" || extension === "png" || extension === "jpeg")
  //   {
  //     filePermitted = true
  //   }
  //   if (!filePermitted) {
  //     message.error('File format not supported. Only images are allowed. Allowed formats: JPG / PNG');
  //   }

  //   let fileSizeAllowed = false
  //   fileSizeAllowed = file.size / 1024 / 1024 < 10; // file size should be less than 655kb
  //   if (!fileSizeAllowed) {
  //     message.error(`File must smaller than 10mb!!`);
  //   }
  //   return filePermitted && fileSizeAllowed;
  // }

  // fileUpload = ({ file, onSuccess, onProgress, onError, data }) => {
  //   const { dispatch, currentScene, index } = this.props
  //   dispatch({
  //     type: actions.UPLOAD_MOSAIC_IMAGE,
  //     payload: {
  //       file,
  //       clickSectionIndex: index,
  //       downloadIndex: data,
  //       uploadtype: 'fileupload',
  //       onSuccess,
  //       onError,
  //       onProgress,
  //       currentScene,
  //     },
  //   })
  // }

  handleVisibleChange = () => {
    const {
      dispatch,
      mosaic: { webcamvisible },
    } = this.props
    dispatch({
      type: actions.SET_STATE,
      payload: {
        webcamLoading: true,
        webcamvisible: !webcamvisible,
      },
    })
  }

  handleStart=()=>{
    const { dispatch } = this.props
    dispatch({
      type: actions.SET_STATE,
      payload: {
        isMosaicWallOpen: true,
        // isMosaicIntroOpen: false
      },
    })
  }

  handleClose = () => {
    const { dispatch } = this.props
    dispatch({
      type: actions.SET_STATE,
      payload: {
        isMosaicWallOpen: false,
        isMosaicIntroOpen: false
      },
    })
  }

  render() {
    const {
      mosaic: { isMosaicWallOpen, isMosaicIntroOpen, mosaicWallURL, webcamvisible },
    } = this.props
    return (
      <div>
        <Modal
          visible={isMosaicIntroOpen}
          style={{
            top: 0,
            // display: 'flex',
            maxHeight: '100vh',
          }}
          wrapClassName="mosaicwallModal"
          maskClosable={false}
          maskStyle={{ backgroundColor: 'rgba(15, 12, 41, 0.9)' }}
          mask
          maxWidth="80rem"
          onOk={this.handleClose}
          confirmLoading
          onCancel={this.handleClose}
          footer={null}
          closeIcon={<CloseCircleOutlined style={{ color: 'white', fontSize: '25px' }} />}
          destroyOnClose
        >
          {/* <Row gutter={24} id="modalHeader" className="modalHeader">
            <Col span={24}>
              <img
                src="./resources/images/agendaHeader.jpg"
                style={{ width: '100%' }}
                alt="modalHeader"
              />
            </Col>
          </Row> */}
          <Row id="modalContent" className="modalContent">
            <Scrollbars autoHeight autoHeightMin="100px" autoHeightMax="calc(100vh - 10rem)">
              <Col span={24}>
                {isMosaicWallOpen ?
                  <Iframe
                    // url={`${mosaicWallURL}?val=${mosaiciFrameKey}`}
                    url={mosaicWallURL}
                    width="100%"
                    height="calc(100vh - 10rem)"
                    // id={newGameID}
                    // key={mosaiciFrameKey}
                    loading="eager"
                    className="theMosaiciFrame"
                    display="initial"
                    position="relative"
                    frameborder="0"
                  // style={{border:0,height:"800px",width:"100%"}}
                  // loading="lazy"
                  />
                  :
                  <Row>
                    <Col span={24} className="text-center intro">
                      <h1 className='white-text'>{process.env.REACT_APP_EVENT_NAME} Mosaic Wall</h1>
                      <br />
                      <h5 className='white-text'>What makes slash n unique is each of you. Take a picture of yourself and watch it</h5>
                      {/* <br /> */}
                      <h5 className='white-text'>become a part of the slash n mnemonic! </h5>
                      {/* <br /> */}
                      <h5 className='white-text'>#StartClicking</h5>
                      <br />
                      <Button type="primary" shape="round" icon={<PlayCircleFilled />} onClick={this.handleStart} size="large">
                        Start
                      </Button>
                    </Col>
                  </Row>

                }
              </Col>
            </Scrollbars>
          </Row>
          {isMosaicWallOpen &&
            <Row
              justify="space-around"
              align="middle"
              id="modalFooter"
              style={{ padding: '10px' }}
              className="modalFooter"
            >
              <Col span={6} className="text-center">
                {/* <Upload 
                beforeUpload={this.beforeUpload}
                customRequest={this.fileUpload}
              >
                <Tooltip title="Upload Asset">
                  <Button>
                    <UploadOutlined /> Click to Upload
                  </Button>
                </Tooltip>
              </Upload> */}
                <Popover
                  content={webcamvisible ? <MosaicPhotobooth /> : ''}
                  title="Take a selfie"
                  trigger="click"
                  visible={webcamvisible}
                  overlayClassName="mosaicCamerapopover"
                  onVisibleChange={this.handleVisibleChange}
                >
                  <Button
                    type="primary"
                    shape="circle"
                    onClick={this.capture}
                    icon={<CameraOutlined />}
                    size="large"
                  />
                </Popover>
              </Col>
            </Row>
          }
        </Modal>
      </div>
    )
  }
}

export default MosaicWall
