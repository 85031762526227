import { all, call, takeEvery, select, put } from 'redux-saga/effects'
import { rerenderMosaically, uploadMosaically } from 'services/virtual/event'
import { uploadFile, uploadBase64Image } from 'services/virtual/general'
import { message } from 'antd'
// import uuid from 'react-uuid'
// import { store as reduxStore } from 'index'
import actions from './actions'
import photoboothActions from '../photobooth/actions'

export function* UPLOAD_MOSAIC_IMAGE(data) {
  try {
    const config = {
      onUploadProgress: progressEvent => {
        const percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total)
        console.log(percentCompleted)
        data.payload.onProgress({ percent: percentCompleted })
      },
    }

    message.success("Upload Initiated. You'll be notified when the process is finished.")
    let result = {}
    if (data.payload.uploadtype === 'base64') {
      result = yield call(uploadBase64Image, { imageData: data.payload.imageString })
    } else {
      result = yield call(uploadFile, { file: data.payload.file, config })
    }
    if (result.status === 200) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          mosaicWallTempURL: result.data.fileUrl,
          imageSubmitLoading: false,
          webcamvisible: false,
          webcamLoading: true,
          mosaicphotoboothImage: '',
          currentStep: 0,
        },
      })

      const state = yield select()
      const {
        mosaic: { mosaicID, MOSAICALLY_API_KEY },
        virtualUser: { name },
      } = state
      yield call(uploadMosaically, {
        mosaicID,
        mosaicKey: MOSAICALLY_API_KEY,
        name,
        source: result.data.fileUrl,
      })
      const renderresult = yield call(rerenderMosaically, {
        mosaicID,
        mosaicKey: MOSAICALLY_API_KEY,
      })
      if (renderresult) {
        yield put({
          type: actions.SET_STATE,
          payload: {
            isMosaicWallOpen: false,
            isMosaicIntroOpen:false
          },
        })

        yield put({
          type: photoboothActions.SET_STATE,
          payload: {
            mosaicUploadLoader: false,
          },
        })
        // setTimeout(()=> {
        //   reduxStore.dispatch({
        //     type: actions.SET_STATE,
        //     payload: {
        //       isMosaicWallOpen: true
        //     },
        //   })
        // },3000)
        message.success(
          'File Uploaded Successfully. Congratulations on being a part of a Bigger Picture.',
        )
      }

      if (Object.prototype.hasOwnProperty.call(data.payload, 'onSuccess')) {
        data.payload.onSuccess('ok')
      }
    } else {
      data.payload.onError({ event: result.statusText })
      message.error('Error occured in uploading the Image.')
    }
  } catch (err) {
    console.log(err.response)
    console.log(err)
    data.payload.onError({ event: err.response.data.message })
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.UPLOAD_MOSAIC_IMAGE, UPLOAD_MOSAIC_IMAGE),
    // takeEvery(actions.GET_AGENDA, GET_AGENDA)
  ])
}
